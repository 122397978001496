<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_arpmd',
      headers: [
        [{
          label: 'Tahun Akademik',
          dataKey: 'tahun_akademik',
        }, {
          label: 'Judul PKM',
          dataKey: 'judul_penelitian',
        }, {
          label: 'Nama Ketua Tim',
          dataKey: 'data_master_dosen.full_name',
        }, {
          label: 'Kepakaran Ketua Tim	',
          dataKey: 'kepakaran_ketua_tim',
        }, {
          label: 'Nama dan Identitas Dosen Anggota Penelitian	',
          dataKey: 'nama_dan_identitas_dosen_anggota_penelitian',
          render(data) {
            const researchMembers = data.map(member => `<li class="badge badge-success badge-sm d-inline-block text-left px-1">${member}</li>`)
            return `<ul class="px-2 pt-1">${researchMembers}</ul>`.replace(/,/g, ' ')
          }
        }, {
          label: 'Nama dan Identitas Mahasiswa yang dilibatkan',
          dataKey: 'nama_dan_identitas_mahasiswa_yang_dilibatkan',
          render(data) {
            const researchMembers = data.map(member => `<li class="badge badge-success badge-sm d-inline-block text-left px-1">${member}</li>`)
            return `<ul class="px-2 pt-1">${researchMembers}</ul>`.replace(/,/g, ' ')
          }
        }]
      ],
      form: {
        inputs: [{
          label: 'Tahun Akademik',
          name: 'tahun_akademik',
          dataKey: 'tahun_akademik',
          type: 'text',
          
          placeholder: `${moment().format('YYYY')}/${moment().add(1, 'years').format('YYYY')}`
        }, {
          label: 'Judul PKM',
          name: 'judul_penelitian',
          dataKey: 'judul_penelitian',
          type: 'text',
          
        }, {
          label: 'Nama Ketua Tim',
          name: 'data_master_dosen_id',
          async dataKey(data, input) {
            const response = await useJwt.http.get(`data_master_dosen/${data.data_master_dosen_id}?state=all`)
            if (response.data.data_master_dosens) {
              const { id: value, full_name: label } = response.data.data_master_dosens
              input.options = [{ label, value }]
              return value
            }
            return 0
          },
          type: 'select',
          options: [],
          placeholder: 'Cari nama dosen',
          rules: 'required',
          async fetchOptions(search, loading) {
            if (search.length >= 3) {
              loading(true)
              const response = await useJwt.http.get('data_master_dosen', { params: { full_name: search } })
              const fetchedOptions = response.data.data_master_dosens.map(dosen => ({ label: dosen.full_name, value: dosen.id }))
              loading(false)
              return fetchedOptions
            }
          },
          customSelectedValue(data) {
            return data.value
          },
        }, {
          label: 'Kepakaran Ketua Tim	',
          name: 'kepakaran_ketua_tim',
          dataKey: 'kepakaran_ketua_tim',
          type: 'text',
          
        }, {
          label: 'Nama dan Identitas Dosen Anggota Penelitian	',
          name: 'nama_dan_identitas_dosen_anggota_penelitian',
          dataKey: 'nama_dan_identitas_dosen_anggota_penelitian',
          type: 'select',
          options: [],
          placeholder: 'Cari nama dosen',
          multiple: true,
          fetchOptions(search) {
            if (search.length >= 3) {
              return [search]
            }
          },
        }, {
          label: 'Nama dan Identitas Mahasiswa yang dilibatkan',
          name: 'nama_dan_identitas_mahasiswa_yang_dilibatkan',
          dataKey: 'nama_dan_identitas_mahasiswa_yang_dilibatkan',
          type: 'select',
          options: [],
          placeholder: 'Cari nama dosen',
          multiple: true,
          fetchOptions(search) {
            if (search.length >= 3) {
              return [search]
            }
          },
        }]
      },
    }
  },
}
</script>
